import React from 'react';



const Footer = () => {

    



    return(
        <>
        <footer>
            <div id='footmenu'>
            <p>Made by maksderylo</p>
            </div>

        <div className='textbotmen'><ul>
          <li><a href="https://github.com/maksderylo">GitHub,</a></li>
          <li><a href="https://www.linkedin.com/in/maksymilian-dery%C5%82o-677954261">LinkedIn,</a></li>
          <li><a href='mailto:derylomaksoff@gmail.com'>Mail</a></li>
        </ul>
        </div>
        </footer>
        <div id='footerspace'></div>
        </>

    );

}

export default Footer;